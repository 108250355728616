<template>
  <div>
    <dashboard-page-title :showFilter="true" :showSearch="true" :showExportation="true" @addEvent="$router.push({name: 'driverAddVehicles'})">الشاحنات</dashboard-page-title>

    <main-table :fields="fields" :baseURL="VUE_APP_TRANSPORTATION_LINK" :list_url="'vehicles/driver'" :status-url="'vehicles/status'" ></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_TRANSPORTATION_LINK } from '@/config/constant'
export default {
  name: 'index',
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_TRANSPORTATION_LINK,
      fields: [
        { label: 'صور المركبة', key: 'featuredImage', type: 'image', class: 'text-right' },
        { label: 'اسم المركبة', key: 'model', type: 'name', class: 'text-right' },
        { label: 'عدد الرحلات', key: 'numberOfTrips', type: 'number', class: 'text-right' },
        { label: 'اجمالى الأرباح', key: 'profits', class: 'text-right' },
        {
          label: 'الحالة',
          key: 'change_status'
        }
        // {
        //   label: this.$t('drivers.control'),
        //   key: 'actions',
        //   class: 'text-right',
        //   type: 'actions',
        //   actions: [
        //     {
        //       text: 'view',
        //       icon: 'ri-eye-fill',
        //       color: 'primary',
        //       ifNavigate: true,
        //       routePath: 'show-driver/:id'
        //     },
        //     {
        //       text: 'edit',
        //       icon: 'ri-ball-pen-fill',
        //       color: 'info',
        //       ifNavigate: true,
        //       routePath: 'edit-driver/:id'
        //     },
        //     {
        //       text: 'Delete',
        //       icon: 'ri-delete-bin-line',
        //       color: 'danger',
        //       url: 'drivers',
        //       baseUrl: VUE_APP_TRANSPORTATION_LINK,
        //       titleHeader: 'سائق',
        //       question: 'هل متأكد أنك تريد مسح هذا السائق',
        //       textContent: 'name',
        //       textDeleteButton: 'بالتأكيد, مسح',
        //       textCancelButton: 'الغاء',
        //       showAlert: true
        //     }
        //   ]
        // }
      ]
    }
  }

}
</script>
